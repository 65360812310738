<template>
  <div class="min-h-16">
    <div
      class="h-full px-4 py-3 rounded"
      :class="{
        'bg-background-soft': isSearch(),
        'bg-background-50 border border-gray-soft': !isSearch()
      }"
    >
      <template v-if="isText()">
        <input
          :placeholder="placeholder"
          class="font-body-1 text-body-1 bg-transparent focus:outline-none focus:rang w-full placeholder-gray-500 "
          type="text"
          @input="updateInput($event)"
          @keyup.enter="onEnter()"
          :value="value"
          :disabled="disabled"
        />
      </template>
      <template v-if="isSearch()">
        <div class="inline-block float-left px-3 w-1/12 h-full">
          <svg
            class=" align-middle h-full"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.71 19.29L17.31 15.9C18.407 14.5025 19.0022 12.7767 19 11C19 9.41775 18.5308 7.87103 17.6518 6.55544C16.7727 5.23985 15.5233 4.21447 14.0615 3.60897C12.5997 3.00347 10.9911 2.84504 9.43928 3.15372C7.88743 3.4624 6.46197 4.22433 5.34315 5.34315C4.22433 6.46197 3.4624 7.88743 3.15372 9.43928C2.84504 10.9911 3.00347 12.5997 3.60897 14.0615C4.21447 15.5233 5.23985 16.7727 6.55544 17.6518C7.87103 18.5308 9.41775 19 11 19C12.7767 19.0022 14.5025 18.407 15.9 17.31L19.29 20.71C19.383 20.8037 19.4936 20.8781 19.6154 20.9289C19.7373 20.9797 19.868 21.0058 20 21.0058C20.132 21.0058 20.2627 20.9797 20.3846 20.9289C20.5064 20.8781 20.617 20.8037 20.71 20.71C20.8037 20.617 20.8781 20.5064 20.9289 20.3846C20.9797 20.2627 21.0058 20.132 21.0058 20C21.0058 19.868 20.9797 19.7373 20.9289 19.6154C20.8781 19.4936 20.8037 19.383 20.71 19.29ZM5 11C5 9.81332 5.3519 8.65328 6.01119 7.66658C6.67047 6.67989 7.60755 5.91085 8.7039 5.45673C9.80026 5.0026 11.0067 4.88378 12.1705 5.11529C13.3344 5.3468 14.4035 5.91825 15.2426 6.75736C16.0818 7.59648 16.6532 8.66558 16.8847 9.82946C17.1162 10.9933 16.9974 12.1997 16.5433 13.2961C16.0892 14.3925 15.3201 15.3295 14.3334 15.9888C13.3467 16.6481 12.1867 17 11 17C9.4087 17 7.88258 16.3679 6.75736 15.2426C5.63214 14.1174 5 12.5913 5 11Z"
              fill="#212121"
            />
          </svg>
        </div>
        <input
          :placeholder="placeholder"
          class="px-3 font-base text-base bg-transparent inline-block align-middle h-full focus:outline-none focus:rang w-10/12 placeholder-gray-500 "
          type="text"
          :value="value"
          :disabled="disabled"
          v-on:keyup.enter="search($event)"
          @input="updateInput($event)"
        />
      </template>
      <template v-if="isEmail()">
        <input
          :placeholder="placeholder"
          @keyup.enter="onEnter()"
          class="font-body-1 text-body-1 bg-transparent focus:outline-none focus:rang w-full placeholder-gray-500 "
          type="email"
          :disabled="disabled"
          :value="value"
          @input="updateInput($event)"
        />
      </template>
      <template v-if="isDate()">
        <v-date-picker
          class="inline-block w-5/6 "
          mode="date"
          :value="value"
          @input="updateDateInput($event)"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              :placeholder="placeholder"
              class="font-body-1 text-body-1 bg-transparent focus:outline-none focus:rang placeholder-gray-500"
              type="text"
              :value="inputValue"
              v-on="inputEvents"
              :disabled="disabled"
            />
          </template>
        </v-date-picker>
        <div class="inline-block w-1/6 align-middle text-right">
          <svg
            class="float-right"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 4H18V3C18 2.45 17.55 2 17 2C16.45 2 16 2.45 16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 19C19 19.55 18.55 20 18 20H6C5.45 20 5 19.55 5 19V9H19V19ZM7 11H9V13H7V11ZM11 11H13V13H11V11ZM15 11H17V13H15V11Z"
              fill="#9E9E9E"
            />
          </svg>
        </div>
      </template>
      <template v-if="isPassword()">
        <input
          :placeholder="placeholder"
          class="font-body-1 text-body-1 bg-transparent inline-block w-5/6 focus:outline-none focus:rang placeholder-gray-500"
          @keyup.enter="onEnter()"
          :type="isPasswordRevealed ? 'text' : 'password'"
          :disabled="disabled"
          @input="updateInput($event)"
        />
        <div
          class="inline-block w-1/6 align-middle"
          v-on:click="togglePasswordReveal()"
        >
          <svg
            class="float-right"
            width="22"
            height="22"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 4.49999C13.76 4.49999 16 6.73999 16 9.49999C16 10.01 15.9 10.5 15.76 10.96L18.82 14.02C20.21 12.79 21.31 11.25 22 9.48999C20.27 5.10999 16 1.99999 11 1.99999C9.73 1.99999 8.51 2.19999 7.36 2.56999L9.53 4.73999C10 4.59999 10.49 4.49999 11 4.49999ZM1.71 1.15999C1.32 1.54999 1.32 2.17999 1.71 2.56999L3.68 4.53999C2.06 5.82999 0.77 7.52999 0 9.49999C1.73 13.89 6 17 11 17C12.52 17 13.97 16.7 15.31 16.18L18.03 18.9C18.42 19.29 19.05 19.29 19.44 18.9C19.83 18.51 19.83 17.88 19.44 17.49L3.13 1.15999C2.74 0.769993 2.1 0.769993 1.71 1.15999ZM11 14.5C8.24 14.5 6 12.26 6 9.49999C6 8.72999 6.18 7.99999 6.49 7.35999L8.06 8.92999C8.03 9.10999 8 9.29999 8 9.49999C8 11.16 9.34 12.5 11 12.5C11.2 12.5 11.38 12.47 11.57 12.43L13.14 14C12.49 14.32 11.77 14.5 11 14.5ZM13.97 9.16999C13.82 7.76999 12.72 6.67999 11.33 6.52999L13.97 9.16999Z"
              :fill="isPasswordRevealed ? '#212121' : '#9E9E9E'"
            />
          </svg>
        </div>
      </template>
    </div>
    <template v-if="!hasAddon() && !isSearch() && !isErrorHidden()">
      <div class="font-caption text-caption px-4 py-2" :class="warningClass">
        {{warning}}
      </div>
    </template>
    <template v-if="hasAddon() && !isSearch() && !isErrorHidden()">
      <div class="font-caption text-caption px-4 py-2 w-full" :class="warningClass">
        {{warning}}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {},
    warning: {
      type: String,
      default: ''
    },
    warningClass: {
      type: String,
      default: 'text-gray-500'
    },
    isError: {
      type: Boolean,
      default: false
    },
    type: {
      validator: value => {
        return (
          ['text', 'search', 'email', 'password', 'date'].indexOf(value) !== -1
        )
      },
      default: () => {
        return 'text'
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    hideErrors: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isPasswordRevealed: false
    }
  },
  methods: {
    onEnter () {
      this.$emit('enter-pressed')
    },
    isText () {
      return this.type === 'text'
    },
    isDate () {
      return this.type === 'date'
    },
    isSearch () {
      return this.type === 'search'
    },
    isEmail () {
      return this.type === 'email'
    },
    isPassword () {
      return this.type === 'password'
    },
    isErrorHidden () {
      return this.hideErrors
    },
    hasAddon () {
      return ['search', 'email', 'password', 'date'].indexOf(this.type) !== -1
    },
    togglePasswordReveal () {
      this.isPasswordRevealed = !this.isPasswordRevealed
    },
    updateInput (el) {
      this.$emit('input', el.target.value)
    },
    updateDateInput (el) {
      this.$emit('input', el)
    },
    search (el) {
      if (el.target.value !== '') {
      // console.log('emitting')
        this.$emit('find', el.target.value)
      }
    }
  }
}
</script>
